import type { IContentPage, IData } from '@/models'
import { BAlert, BButton, BButtonGroup, BCard, BCollapse, BContainer, BDropdownDivider, BDropdownItem, BIconPersonCircle, BIconQuestionCircle, BImg, BNavForm, BNavItem, BNavItemDropdown, BNavText, BNavbar, BNavbarBrand, BNavbarNav, BNavbarToggle } from 'bootstrap-vue'
import { startCase } from 'lodash-es'
import { Component, ProvideReactive } from 'vue-property-decorator'
import ExpandTransition from '~/components/ExpandTransition'
import Footer from '~/components/Footer'
import MySpinner from '~/components/MySpinner'
import StateComponent from '~/models/StateComponent'
import { adminArea, agenciesPath, applicationsPath, benefitCategoriesPath, benefitsPath, bundlesPath, businessApplicationsPath, businessArea, businessIndicativeQuotesPath, businessQuotesPath, contentPagesPath, customerNeedsPath, dashboardPath, discountingArea, discountTypesPath, factFindsPath, healthcarePlansPath, helpPath, hospitalListsPath, imagesPath, indicativeQuotesPath, insurerDiscountsPath, insurerExcessAmountsPath, insurerHospitalListsPath, insurerModuleGroupsPath, insurerModulesPath, insurerSchemesPath, insurersPath, legacyArea, medicalConditionGroupsPath, memberMixDiscountsPath, midTermLoadingsPath, minimumPremiumsPath, moduleGroupsPath, modulesPath, mtjCasesPath, newBusinessArea, notificationsPath, personalApplicationsPath, personalArea, personalQuotesPath, postcodeAreasPath, processingArea, productsPath, queryTypesPath, quoteRequestsPath, quotesPath, regionsPath, settingsPath, templatesPath, tenureDiscountsPath, underwritingArea, underwritingQuestionAnswersPath, underwritingQuestionsPath, underwritingRiskScoresPath, usersPath } from '~/router'
import { getHtmlContent, mapToIOptions, repository, setProfile } from '~/utils'

@Component
export default class Home extends StateComponent {
    pages: IContentPage[] = []

    @ProvideReactive() data: IData = {
        titles: [],
        genders: [],
        industryTypes: [],
        leadTypes: []
    }

    get availablePages() {
        return this.pages
            .filter(p => this.isAdminPage || p.type === (this.isBusinessPage ? 'Business' : 'Personal'))
    }

    async created() {
        await setProfile()

        let path = this.$route.path

        if (
            this.areas.length > 0 &&
            !path.startsWith(`/${helpPath}`) &&
            (
                path === '/' ||
                !this.areas.some(area => path.startsWith(`/${area}`))
            )
        )
            path = `/${this.areas[0]}`

        if (path !== this.$route.path)
            await this.$router.push({ path })
    }

    async mounted() {

        const [pages, titles, genders, industryTypes, leadTypes] =
            await Promise.all([
                repository.getPages(),
                repository.getTitles(),
                repository.getGenders(),
                repository.getIndustryTypes(),
                repository.getLeadTypes()
            ])

        this.pages = pages

        this.data.titles = mapToIOptions(titles)
        this.data.genders = mapToIOptions(genders)
        this.data.industryTypes = mapToIOptions(industryTypes)
        this.data.leadTypes = mapToIOptions(leadTypes)
    }

    getPath(page: IContentPage) {
        return `/${page.type?.toLowerCase()}/${helpPath}/${page.id}`
    }

    render() {
        return (
            <transition name="bounce-fade" mode="out-in">
                {
                    !this.profile ?
                        <MySpinner /> :
                        <div class="d-flex flex-column h-100 w-100">
                            <header>
                                <BNavbar toggleable="xl" class="navbar-vitality">

                                    <BNavbarToggle target="nav_collapse" class="border-0" />

                                    <BNavbarBrand>
                                        <BImg alt="Vitality logo" height={30} src={require('~/assets/vitality-logo.svg')} />
                                    </BNavbarBrand>

                                    <BCollapse is-nav id="nav_collapse">
                                        <BNavbarNav>
                                            {
                                                this.isPersonalPage &&
                                                [
                                                    <BNavItem to={`/${personalArea}/${quotesPath}`}>Quotes</BNavItem>,
                                                    <BNavItem to={`/${personalArea}/${applicationsPath}`}>Applications</BNavItem>
                                                ]
                                            }
                                            {
                                                this.isBusinessPage &&
                                                [
                                                    this.isInternalAgent ?
                                                        [
                                                            <BNavItem to={`/${businessArea}/${factFindsPath}`}>Fact finds</BNavItem>,
                                                            <BNavItemDropdown text="Quotes">
                                                                <BDropdownItem to={`/${businessArea}/${indicativeQuotesPath}`}>Indicative quotes</BDropdownItem>
                                                                <BDropdownItem to={`/${businessArea}/${quotesPath}`}>Formal quotes</BDropdownItem>
                                                            </BNavItemDropdown>
                                                        ] :
                                                        <BNavItem to={`/${businessArea}/${quotesPath}`}>Quotes</BNavItem>,
                                                    <BNavItem to={`/${businessArea}/${applicationsPath}`}>Applications</BNavItem>
                                                ]
                                            }
                                            {
                                                this.isProcessingPage &&
                                                [
                                                    <BNavItem to={`/${processingArea}/${businessQuotesPath}`}>Business quotes</BNavItem>
                                                ]
                                            }
                                            {
                                                this.isDiscountingPage &&
                                                [
                                                    <BNavItem to={`/${discountingArea}/${businessQuotesPath}`}>Business quotes</BNavItem>
                                                ]
                                            }
                                            {
                                                this.isNewBusinessPage &&
                                                [
                                                    <BNavItemDropdown text="Personal">
                                                        <BDropdownItem to={`/${newBusinessArea}/${personalQuotesPath}`}>Quotes</BDropdownItem>
                                                        <BDropdownItem to={`/${newBusinessArea}/${personalApplicationsPath}`}>Applications</BDropdownItem>
                                                    </BNavItemDropdown>,
                                                    <BNavItemDropdown text="Business">
                                                        <BDropdownItem to={`/${newBusinessArea}/${businessQuotesPath}`}>Quotes</BDropdownItem>
                                                        <BDropdownItem to={`/${newBusinessArea}/${businessApplicationsPath}`}>Applications</BDropdownItem>
                                                    </BNavItemDropdown>
                                                ]
                                            }
                                            {
                                                this.isUnderwritingPage &&
                                                [
                                                    <BNavItemDropdown text="Personal">
                                                        <BDropdownItem to={`/${underwritingArea}/${personalQuotesPath}`}>Quotes</BDropdownItem>
                                                        <BDropdownItem to={`/${underwritingArea}/${personalApplicationsPath}`}>Applications</BDropdownItem>
                                                    </BNavItemDropdown>,
                                                    <BNavItemDropdown text="Business">
                                                        <BDropdownItem to={`/${underwritingArea}/${mtjCasesPath}`}>MTJ cases</BDropdownItem>
                                                    </BNavItemDropdown>
                                                ]
                                            }
                                            {
                                                this.isAdminPage &&
                                                [
                                                    <BNavItem to={`/${adminArea}/${dashboardPath}`}>Dashboard</BNavItem>,
                                                    !this.isMarketing && !this.isPricingAdmin &&
                                                    [
                                                        <BNavItemDropdown text="Personal">
                                                            {
                                                                (this.isProperAdmin || this.isServicingAdmin || this.isManager || this.isClaimsAdmin || this.isBrokerAdmin || this.isNewBusinessAdmin || this.isUnderwritingAdmin) &&
                                                                [
                                                                    <BDropdownItem to={`/${adminArea}/${personalQuotesPath}`}>Personal quotes</BDropdownItem>,
                                                                    <BDropdownItem to={`/${adminArea}/${personalApplicationsPath}`}>Personal applications</BDropdownItem>
                                                                ]
                                                            }
                                                        </BNavItemDropdown>,
                                                        <BNavItemDropdown text="Business">
                                                            {
                                                                (this.isProperAdmin || this.isServicingAdmin || this.isClaimsAdmin || this.isBrokerAdmin || this.isNewBusinessAdmin) &&
                                                                [
                                                                    <BDropdownItem to={`/${adminArea}/${businessIndicativeQuotesPath}`}>Indicative business quotes</BDropdownItem>,
                                                                    <BDropdownItem to={`/${adminArea}/${factFindsPath}`}>Fact finds</BDropdownItem>
                                                                ]
                                                            }
                                                            {
                                                                (this.isProperAdmin || this.isServicingAdmin || this.isManager || this.isClaimsAdmin || this.isBrokerAdmin || this.isNewBusinessAdmin) &&
                                                                [
                                                                    <BDropdownItem to={`/${adminArea}/${businessQuotesPath}`}>Business quotes</BDropdownItem>,
                                                                    <BDropdownItem to={`/${adminArea}/${businessApplicationsPath}`}>Business applications</BDropdownItem>
                                                                ]
                                                            }
                                                            {
                                                                (this.isProperAdmin || this.isServicingAdmin || this.isClaimsAdmin || this.isNewBusinessAdmin || this.isUnderwritingAdmin) &&
                                                                <BDropdownItem to={`/${adminArea}/${mtjCasesPath}`}>MTJ cases</BDropdownItem>
                                                            }
                                                            {
                                                                this.isProperAdmin &&
                                                                [
                                                                    <BDropdownDivider />,
                                                                    <BDropdownItem to={`/${adminArea}/${healthcarePlansPath}`}>Healthcare plans</BDropdownItem>,
                                                                    <BDropdownItem to={`/${adminArea}/${quoteRequestsPath}`}>Quote requests</BDropdownItem>
                                                                ]
                                                            }
                                                        </BNavItemDropdown>
                                                    ],

                                                    (this.isProperAdmin || this.isPricingAdmin) &&
                                                    <BNavItemDropdown text="Settings">
                                                        {
                                                            this.isProperAdmin &&
                                                            [
                                                                <BDropdownItem to={`/${adminArea}/${settingsPath}`}>Application settings</BDropdownItem>,
                                                                <BDropdownDivider />,
                                                                <BDropdownItem to={`/${adminArea}/${usersPath}`}>Users</BDropdownItem>,
                                                                <BDropdownItem to={`/${adminArea}/${agenciesPath}`}>Agencies</BDropdownItem>,
                                                                <BDropdownItem to={`/${adminArea}/${regionsPath}`}>Regions</BDropdownItem>,
                                                                <BDropdownDivider />,
                                                                <BDropdownItem to={`/${adminArea}/${imagesPath}`}>Images</BDropdownItem>,
                                                                <BDropdownItem to={`/${adminArea}/${contentPagesPath}`}>Content pages</BDropdownItem>,
                                                                <BDropdownItem to={`/${adminArea}/${templatesPath}`}>Templates</BDropdownItem>,
                                                                <BDropdownItem to={`/${adminArea}/${customerNeedsPath}`}>Customer needs</BDropdownItem>,
                                                                <BDropdownItem to={`/${adminArea}/${queryTypesPath}`}>Query types</BDropdownItem>,
                                                                <BDropdownItem to={`/${adminArea}/${medicalConditionGroupsPath}`}>Medical condition groups</BDropdownItem>
                                                            ]
                                                        }

                                                        <BDropdownDivider />

                                                        <BDropdownItem to={`/${adminArea}/${insurerDiscountsPath}`}>Insurer discounts</BDropdownItem>
                                                        <BDropdownItem to={`/${adminArea}/${tenureDiscountsPath}`}>Tenure discounts</BDropdownItem>
                                                        <BDropdownItem to={`/${adminArea}/${memberMixDiscountsPath}`}>Member mix discounts</BDropdownItem>
                                                        <BDropdownItem to={`/${adminArea}/${midTermLoadingsPath}`}>Mid term loadings</BDropdownItem>
                                                        <BDropdownItem to={`/${adminArea}/${discountTypesPath}`}>Discount types</BDropdownItem>

                                                        <BDropdownDivider />

                                                        <BDropdownItem to={`/${adminArea}/${postcodeAreasPath}`}>Postcode areas</BDropdownItem>
                                                        <BDropdownItem to={`/${adminArea}/${minimumPremiumsPath}`}>Minimum premiums</BDropdownItem>
                                                        <BDropdownItem to={`/${adminArea}/${underwritingQuestionsPath}`}>Underwriting questions</BDropdownItem>
                                                        <BDropdownItem to={`/${adminArea}/${underwritingQuestionAnswersPath}`}>Underwriting question answers</BDropdownItem>
                                                        <BDropdownItem to={`/${adminArea}/${underwritingRiskScoresPath}`}>Underwriting risk scores</BDropdownItem>
                                                    </BNavItemDropdown>,

                                                    (this.isProperAdmin || this.isPricingAdmin) &&
                                                    <BNavItemDropdown text="Products">
                                                        <BDropdownItem to={`/${adminArea}/${insurersPath}`}>Insurers</BDropdownItem>
                                                        <BDropdownItem to={`/${adminArea}/${insurerExcessAmountsPath}`}>Insurer excess amounts</BDropdownItem>
                                                        <BDropdownItem to={`/${adminArea}/${insurerHospitalListsPath}`}>Insurer hospital lists</BDropdownItem>
                                                        <BDropdownItem to={`/${adminArea}/${insurerModuleGroupsPath}`}>Insurer module groups</BDropdownItem>
                                                        <BDropdownItem to={`/${adminArea}/${insurerModulesPath}`}>Insurer modules</BDropdownItem>
                                                        <BDropdownItem to={`/${adminArea}/${insurerSchemesPath}`}>Insurer schemes</BDropdownItem>
                                                        <BDropdownItem to={`/${adminArea}/${productsPath}`}>Insurer products</BDropdownItem>
                                                        {
                                                            this.isProperAdmin &&
                                                            [
                                                                <BDropdownDivider />,
                                                                <BDropdownItem to={`/${adminArea}/${benefitsPath}`}>Vitality benefits</BDropdownItem>,
                                                                <BDropdownItem to={`/${adminArea}/${benefitCategoriesPath}`}>Vitality benefit categories</BDropdownItem>,
                                                                <BDropdownItem to={`/${adminArea}/${bundlesPath}`}>Vitality bundles</BDropdownItem>,
                                                                <BDropdownItem to={`/${adminArea}/${hospitalListsPath}`}>Vitality hospital lists</BDropdownItem>,
                                                                <BDropdownItem to={`/${adminArea}/${modulesPath}`}>Vitality modules</BDropdownItem>,
                                                                <BDropdownItem to={`/${adminArea}/${moduleGroupsPath}`}>Vitality module groups</BDropdownItem>
                                                            ]
                                                        }
                                                    </BNavItemDropdown>,

                                                    (this.isProperAdmin || this.isServicingAdmin) &&
                                                    <BNavItem to={`/${adminArea}/${notificationsPath}`}>Notifications</BNavItem>,

                                                    this.isManager &&
                                                    <BNavItem to={`/${adminArea}/${usersPath}`}>Advisers</BNavItem>,

                                                    this.isMarketing &&
                                                    <BNavItem to={`/${adminArea}/${templatesPath}`}>Templates</BNavItem>
                                                ]
                                            }
                                        </BNavbarNav>

                                        <BNavbarNav class="ml-auto">
                                            {
                                                this.areas.length > 1 &&
                                                <BNavForm class="mr-3">
                                                    <BButtonGroup>
                                                        {
                                                            ...this.areas.filter(a => a !== legacyArea).map(area =>
                                                                <BButton variant="outline-primary" to={`/${area}`} size="sm">
                                                                    {startCase(area.replace('-', ' '))}
                                                                </BButton>
                                                            )
                                                        }
                                                    </BButtonGroup>
                                                </BNavForm>
                                            }

                                            {
                                                this.profile.paraplannerUser &&
                                                <BNavText class="px-3 bg-important text-bold"><strong>{`Currently acting as: ${this.profile.fullName}`}</strong></BNavText>
                                            }

                                            {
                                                this.availablePages.length > 1 ?
                                                    <BNavItemDropdown right>
                                                        <template slot="button-content">
                                                            <BIconQuestionCircle />
                                                        </template>

                                                        {
                                                            this.availablePages.map(page =>
                                                                <BDropdownItem to={this.getPath(page)}>{page.title}</BDropdownItem>
                                                            )
                                                        }
                                                    </BNavItemDropdown> :
                                                    this.availablePages[0] &&
                                                    <BNavItem to={this.getPath(this.availablePages[0])}>
                                                        <BIconQuestionCircle />
                                                    </BNavItem>
                                            }

                                            <BNavItemDropdown right toggle-class="pr-0">
                                                <template slot="button-content">
                                                    <span v-b-tooltip_hover title={this.profile.paraplannerUser?.entityNumber ?? this.profile.entityNumber ?? undefined}>
                                                        <BIconPersonCircle />
                                                        <span class="d-xl-none d-xxl-inline-block ml-1">{this.profile.paraplannerUser?.fullName ?? this.profile.fullName}</span>
                                                    </span>

                                                    {
                                                        this.profile.activeAgency &&
                                                        <span class="d-xl-none d-xxl-inline-block ml-1">(<strong v-b-tooltip_hover title={this.profile.activeAgency.name}>{this.profile.activeAgency.id}</strong>)</span>
                                                    }
                                                </template>

                                                <BDropdownItem href={`/identity/account/manage`}>Manage account</BDropdownItem>
                                                {
                                                    this.isPersonalPage &&
                                                    <BDropdownItem href={`/${personalArea}/${bundlesPath}`}>Manage bundles</BDropdownItem>
                                                }
                                                <BDropdownItem on-click={this.logOut}>Sign out</BDropdownItem>

                                            </BNavItemDropdown>
                                        </BNavbarNav>

                                    </BCollapse>
                                </BNavbar>
                            </header>

                            <main role="main" class="flex-shrink-0">
                                {
                                    this.isAdviserPage && (this.settings?.homeAlert || this.settings?.offerEnabled) &&
                                    <div class="bg-white">
                                        <BContainer fluid="xxl">
                                            {
                                                this.settings?.homeAlert &&
                                                <div class="py-3">
                                                    <BAlert variant="info" show class="mb-0 pb-0" domPropsInnerHTML={getHtmlContent(this.settings.homeAlert)} />
                                                </div>
                                            }
                                            {
                                                this.settings?.offerEnabled &&
                                                <ExpandTransition>
                                                    {
                                                        this.offer &&
                                                        <div>
                                                            <div class="py-3">
                                                                <BCard>
                                                                    {
                                                                        this.settings.offerImage &&
                                                                        <img alt="Offer image" height={100} style="float: right;" src={this.settings.offerImage} />
                                                                    }
                                                                    <div domPropsInnerHTML={getHtmlContent(this.settings.offerText)} />

                                                                    <BButton variant="outline-primary" size="sm" on-click={() => this.store.mutateOffer(!this.store.offer)}>Hide</BButton>
                                                                </BCard>
                                                            </div>
                                                        </div>
                                                    }
                                                </ExpandTransition>
                                            }
                                        </BContainer>
                                    </div>
                                }

                                <router-view />
                                
                            </main>

                            <Footer />

                        </div>
                }
            </transition>
        )
    }
}
