import { config } from '~/data/defaults.json'
import { adminArea, businessArea, customerArea, discountingArea, legacyArea, newBusinessArea, newPath, pdfArea, personalArea, processingArea, underwritingArea } from '~/router'
import { isRoute, postAsync } from '~/utils'
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class BaseComponent extends Vue {

    get matchedRoutes() {
        return this.$route.matched.reverse()
    }

    get name() {
        return this.$route.meta?.entityName as string | undefined ?? ''
    }

    get apiUrl() {
        return this.$route.meta?.apiUrl as string | undefined ?? ''
    }

    get updateUrl() {
        return this.$route.meta?.updateUrl as string | undefined ?? ''
    }

    get routePath() {
        return this.$route.path
    }

    get createNewPath() {
        return `${this.routePath}/${newPath}`
    }

    isArea(area: string) {
        return isRoute(this.$route, `/${area}`)
    }

    get isPdfPage() {
        return this.isArea(pdfArea)
    }

    get isCustomerPage() {
        return this.isArea(customerArea)
    }

    get isAdminPage() {
        return this.isArea(adminArea)
    }

    get isPersonalPage() {
        return this.isArea(personalArea)
    }

    get isBusinessPage() {
        return this.isArea(businessArea)
    }

    get isAdviserPage() {
        return this.isPersonalPage || this.isBusinessPage
    }

    get isLegacyPage() {
        return this.isArea(legacyArea)
    }

    get isNewBusinessPage() {
        return this.isArea(newBusinessArea)
    }

    get isProcessingPage() {
        return this.isArea(processingArea)
    }

    get isDiscountingPage() {
        return this.isArea(discountingArea)
    }

    get isUnderwritingPage() {
        return this.isArea(underwritingArea)
    }

    pageIsReady() {
        document.dispatchEvent(new CustomEvent(config.pageReadyEvent))
    }

    showModal(message: string, title?: string) {
        document.dispatchEvent(new CustomEvent('modal', { detail: { title, message } }))
    }

    async logOut() {
        const response = await postAsync('/Account/LogOut')

        if (response && response.status !== 400)
            window.location.reload()
    }

    // bubble up
    triggerDataReset() {
        this.$emit('reset')
    }
}