import BaseComponent from '~/models/BaseComponent'
import { adminArea, businessArea, customerArea, discountingArea, newBusinessArea, personalArea, processingArea, underwritingArea } from '~/router'
import { MyStore, useStore } from '~/store'
import { Component } from 'vue-property-decorator'

@Component
export default class StateComponent extends BaseComponent {
    protected store!: MyStore

    get profile() {
        return this.store.profile
    }

    get activeUserId() {
        return this.profile?.id ?? null
    }

    get activeAgencyId() {
        return this.profile?.activeAgency?.id ?? null
    }

    get offer() {
        return this.store.offer
    }

    get settings() {
        return this.store.settings
    }

    get isDirectMarketing() {
        return !!this.profile?.isDirectMarketing
    }

    get isVitalityAgent() {
        return !!this.profile?.isVitalityAgent
    }

    get isInternalAgent() {
        return this.isVitalityAgent || this.isDirectMarketing
    }

    get isTelemed() {
        return !!this.profile?.isTelemed
    }

    get roles() {
        return this.profile?.roles
    }

    get isManager() {
        return this.roles?.some(r => r.endsWith('Manager'))
    }

    get isChannelManager() {
        return this.roles?.some(r => r === 'Channel Manager')
    }

    get isRegionalManager() {
        return this.roles?.some(r => r === 'Regional Manager')
    }

    get isAccountManager() {
        return this.roles?.some(r => r === 'Account Manager')
    }

    get isCustomer() {
        return this.roles?.every(r => r === 'Customer')
    }

    get isAdmin() {
        return this.roles?.some(r => r.endsWith('Admin'))
    }

    get isSystemAdmin() {
        return this.roles?.some(r => r === 'System Admin')
    }

    get isProperAdmin() {
        return this.isSystemAdmin || this.roles?.some(r => r === 'Vitality Admin')
    }

    get showInternalDetails() {
        return this.isProperAdmin || this.isInternalUser
    }

    get isServicingAdmin() {
        return this.roles?.some(r => r === 'Servicing Admin')
    }

    get isClaimsAdmin() {
        return this.roles?.some(r => r === 'Claims Admin')
    }

    get isPricingAdmin() {
        return this.roles?.some(r => r === 'Pricing Admin')
    }

    get isNewBusinessAdmin() {
        return this.roles?.some(r => r === 'New Business Admin')
    }

    get isUnderwritingAdmin() {
        return this.roles?.some(r => r === 'Underwriting Admin')
    }

    get isBrokerAdmin() {
        return this.roles?.some(r => r === 'Broker Admin')
    }

    get isBroker() {
        return this.roles?.some(r => r.startsWith('Broker'))
    }

    get isNewBusiness() {
        return this.roles?.some(r => r.startsWith('New Business'))
    }

    get isUnderwriting() {
        return this.roles?.some(r => r.startsWith('Underwriting'))
    }

    get isProcessing() {
        return this.roles?.some(r => r.startsWith('Processing'))
    }

    get isDiscounting() {
        return this.roles?.some(r => r.startsWith('Discounting'))
    }

    get isMarketing() {
        return this.roles?.some(r => r.startsWith('Marketing'))
    }

    get isUnderwritingOrSystemAdmin() {
        return (this.isUnderwritingAdmin || this.isSystemAdmin)
    }

    get isNewBusinessOrSystemAdmin() {
        return (this.isNewBusinessAdmin || this.isSystemAdmin)
    }

    get isInternalUser() {
        return (
            this.isServicingAdmin ||
            this.isProperAdmin ||
            this.isManager ||
            this.isNewBusiness ||
            this.isUnderwriting ||
            this.isPricingAdmin ||
            this.isClaimsAdmin ||
            this.isProcessing ||
            this.isDiscounting
        )
    }

    get areas() {
        const availableAreas: string[] = []

        if (this.roles?.some(r => r === 'Broker Agent')) {
            availableAreas.push(personalArea)
            //availableAreas.push(legacyArea)
            availableAreas.push(businessArea)
        }

        if (this.roles?.some(r => r === 'New Business Agent'))
            availableAreas.push(newBusinessArea)

        if (this.roles?.some(r => r === 'Underwriting Agent'))
            availableAreas.push(underwritingArea)

        if (this.roles?.some(r => r === 'Processing Agent'))
            availableAreas.push(processingArea)

        if (this.roles?.some(r => r === 'Discounting Agent'))
            availableAreas.push(discountingArea)

        if (this.isCustomer)
            availableAreas.push(customerArea)

        if (this.isAdmin || this.isManager)
            availableAreas.push(adminArea)

        return availableAreas
    }

    get area() {
        return this.routePath.split('/')[1]
    }

    beforeCreate() {
        this.store = useStore()
    }
}
